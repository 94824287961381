<template>
  <span :data-tippy-content="propTitle" @mouseover="handleMouseOver">
    <slot />
  </span>
</template>
<style>
.tippy-box[data-theme~='tomato'] {
  background-color: white;
  color: black;
  border: 1px solid #000000;
  border-radius: 0px;
  font-size: 14px;
  padding: 6px 8px;
}
</style>
<script setup lang="ts">
import tippy, { Instance, Props } from 'tippy.js';

const props = withDefaults(
  defineProps<{
    title: string;
    minWidth?: number;
  }>(),
  {
    minWidth: 100,
  }
);

const instance = ref<Instance<Props>[] | null>();

let initialized = ref(false);

const initTippy = () => {
  if (!initialized.value) {
    instance.value = tippy('[data-tippy-content]', {
      allowHTML: true,
      placement: 'bottom',
      theme: 'tomato',
      arrow: false,
    });
    initialized.value = true;
  }
};

const handleMouseOver = () => {
  initTippy();
};

onBeforeUnmount(() => {
  // Ensure to destroy the tippy instance to avoid memory leaks
  if (instance.value) {
    instance.value.forEach((i) => i.destroy());
  }
});
const propTitle = computed(() => props.title);
</script>
